import React from "react";

import "./Home.css";

const Home = () => {
    return (
        <div className="Home">
            <div className="splash">
                <h1>VΛtriume</h1>
            </div>
        </div>
    );
};

export default Home;
